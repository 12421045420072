.edgebutton {
  width: 20px;
  height: 20px;
  background: #444444;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px;
  line-height: 1;
  border: none;

  svg{
    width: 15px;
    height: 15px;
  fill: rgb(223, 91, 91);
  }
}

.crossHolder{
  position: relative;
  
}

.deleteModal{
  position: absolute; 
  top: -13px;
  left: 100%;
  padding: 5px;
  background-color: #333;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0 0 3px black;
}

.btnHolder{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteModal span{
  font-size: 10px;
  text-wrap: nowrap;
  color: white;
  display: block;
  margin: 3px;
  font-weight: 600;
}

.btnHolder{
  margin-top: 5px;
  padding: 0 5px;
}

.btnHolder button{
  font-size: 10px;
  padding: 5px 10px;
  font-weight: 600;
  color: white;
  background: red;
  border-radius: 5px;
  cursor: pointer;
}


.btnHolder button:first-child{
  background: green;
}