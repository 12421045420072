*,
*::after,
* ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #444444;
}
